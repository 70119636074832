export const BASE_URL: string = process.env.API_ENDPOINT ?? '';
export const MAP_PREFIX = '/geocode/json';
export const SELECTOR_FORM_PEFIX = '/configurator/by-model-number';
export const GOOGLE_MAPS_API = 'https://maps.googleapis.com/maps/api';
export const NAV_SLUG = '/header-navigation';
export const NAV_PARAMS = {
  populate: {
    list: {
      populate: {
        products: {
          populate: '*',
        },
      },
    },
  },
};
export const HOME_SLUG = '/page/homepage';
export const HOME_PARAMS = {
  populate: {
    sections: { populate: '*' },
    product_category_showcase: {
      populate: {
        thumbnail: '*',
        hero: '*',
        products: {
          populate: ['thumbnail'],
        },
      },
    },
    product_showcase: {
      populate: ['thumbnail'],
    },
  },
};
export const FOOTER_LINKS = [
  { href: 'https://support.mrcool.com', text: 'Support', target: '_blank' },
  {
    href: 'https://support.mrcool.com/hc/en-us/requests/new',
    text: 'Contact Us',
    target: '_blank',
  },
  {
    href: '/support/become-reseller',
    text: 'Become a Dealer',
    target: '_blank',
  },
  { href: '/locations', text: 'Locations' },
  { href: '/rebates', text: 'Rebate Center' },
  {
    href: '/support/become-influencer',
    text: 'Become an Influencer',
    target: '_blank',
  },
  { href: '/our-story', text: 'Our Story' },
  { href: '/blog', text: 'Blog' },
  { href: '/system-selector', text: 'System Selector' },
  { href: '/warranty-information', text: 'Warranty Information' },
  { href: '/warranty-registration', text: 'Warranty Registration' },
  { href: '/intelectual-property/patents', text: 'Patents' },
  { href: '/intelectual-property/trademarks', text: 'Trademarks' },
  { href: 'https://mrcool.com/portal', text: 'Partner Center' },
  {
    href: 'https://elegant-novelty-497722905c.strapiapp.com/api/sitemap/index.xml',
    text: 'Sitemap',
  },
];
export const FOOTER_SOCIAL_LINKS = {
  facebook: 'https://www.facebook.com/MRCOOLcomfort',
  instagram: 'https://www.instagram.com/mrcoolcomfort',
  youTube: 'https://www.youtube.com/@MrcoolComfort',
  tiktok: 'https://www.tiktok.com/@mrcooldiy',
  twitter: 'https://twitter.com/mrcoolcomfort',
  pinterest: 'https://www.pinterest.com/mrcoolcomfort',
};
export const FOOTER_TEXT = {
  copyright: 'Copyright © 2024 MRCOOL®. All Rights Reserved.',
  privacyPolicy: 'Privacy Policy',
  termsAndConditions: 'Terms & Conditions',
};
export const BUTTON_TEXT = {
  shopNow: {
    btnText: 'Where to buy',
  },
};
export const REBATES_FOOTER = {
  'diy-4th-generation': {
    colorButton: 'primary',
    classNameButton: 'btn-diy',
  },
  'geo-cool-inverter': {
    colorButton: 'dark',
    classNameButton:
      'border-[#476E30] bg-[#476E30] hover:bg-[#476E30] hover:border-[#476E30] text-white',
  },
  'universal-series': {
    colorButton: 'primary',
    classNameButton: 'btn-universal',
  },
};
