'use client';

import { ecoRebateData } from '@/utils/specificationHelper';
import { FC, useEffect } from 'react';
import Heading from '../common/Heading';
import Link from 'next/link';
import Button from '../common/Button';

export const NoTFoundPage = () => {
  return (
    <section className='flex min-w-full flex-col items-center justify-start bg-white'>
      <div className='container mx-auto mb-12 flex flex-col items-center justify-start gap-y-8 px-5 pb-20 pt-10'>
        <div className='flex h-96 flex-col items-center justify-start gap-y-5 text-center sm:aspect-[13/12] md:aspect-[35/19]'>
          <Heading size='1' className='mt-40 text-4xl md:text-6xl'>
            The page you&#39;re looking for could not be found.
          </Heading>
          <div className='mx-auto w-full'>
            <img
              src='https://res.cloudinary.com/dbkbadq4w/image/upload/v1727363862/404-bear_q1jk4i.png'
              alt='Page not found bear'
              title='Page not found - try searching again'
              className='h-full w-full object-contain'
            />
          </div>
          <Link href={'/'}>
            <Button size='small' title='Back to home'>
              Back to home
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};
