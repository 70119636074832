'use client';

import { useEffect } from 'react';
import {
  BUTTON_TEXT,
  FOOTER_LINKS,
  FOOTER_SOCIAL_LINKS,
  FOOTER_TEXT,
  REBATES_FOOTER,
} from 'utils/constants';
import Link from 'next/link';
import React, { FC } from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaPinterest,
  FaTiktok,
  FaYoutube,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import Button from '../common/Button';
import { RebaseEcoModal } from '../rebates';

const splitLinksIntoColumns = (
  linksArray: { href: string; text: string; target?: string }[]
) => [linksArray.slice(0, 6), linksArray.slice(6)];

export const Footer: FC<{
  slug: string | undefined;
  color?: string;
}> = ({ slug, color }) => {
  const columns = splitLinksIntoColumns(FOOTER_LINKS);
  useEffect(() => {
    const newCss = `
        .eco-m .eco-c a {
          cursor: pointer;
          color : ${color ? color : '#1c8eff'}
        }
      `;
    const styleTag: any = document.createElement('style');
    styleTag.type = 'text/css';
    styleTag.innerHTML = newCss;

    document.body.appendChild(styleTag);
    return () => {
      document.body.removeChild(styleTag);
    };
  }, [slug]);
  return (
    <footer className='relative overflow-hidden bg-white'>
      <div className='mx-auto w-full max-w-[90rem] px-5 pt-16 sm:px-6 lg:px-[120px]'>
        <div className='flex flex-col gap-y-16 sm:gap-y-20'>
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-4 sm:gap-9 lg:grid-cols-5 xl:grid-cols-6'>
            <div className='flex sm:col-span-2 lg:col-span-3 xl:col-span-4' />
            {columns.map((column, colIndex) => (
              <div key={colIndex} className='col-span-1 flex'>
                <div className='flex flex-col gap-y-5'>
                  {column.map((link, i) => (
                    <div key={i}>
                      <Link
                        href={{ pathname: link.href }}
                        scroll={true}
                        target={link.target || '_self'}
                        className='inline-flex gap-x-2 text-[15px] text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600'
                      >
                        {link.text}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className='flex flex-col gap-y-6'>
            <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between'>
              <Link
                href={{ pathname: '/' }}
                scroll={true}
                className='flex-none'
              >
                <img
                  src='/images/mrcool-logo.svg'
                  alt='logo'
                  className='w-full max-w-40'
                />
              </Link>
              <div className='mt-9 flex items-center gap-2 sm:mt-0 sm:justify-center'>
                <div className='grid grid-cols-6 gap-3'>
                  <a
                    className='text-gray inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800'
                    href={FOOTER_SOCIAL_LINKS.facebook}
                    target='_blank'
                  >
                    <FaFacebookF className='h-4 w-4 text-inherit' />
                  </a>
                  <a
                    className='text-gray inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800'
                    href={FOOTER_SOCIAL_LINKS.instagram}
                    target='_blank'
                  >
                    <FaInstagram className='h-4 w-4 text-inherit' />
                  </a>
                  <a
                    className='text-gray inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800'
                    href={FOOTER_SOCIAL_LINKS.youTube}
                    target='_blank'
                  >
                    <FaYoutube className='h-4 w-4 text-inherit' />
                  </a>
                  <a
                    className='text-gray inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800'
                    href={FOOTER_SOCIAL_LINKS.tiktok}
                    target='_blank'
                  >
                    <FaTiktok className='h-4 w-4 text-inherit' />
                  </a>
                  <a
                    className='text-gray inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800'
                    href={FOOTER_SOCIAL_LINKS.twitter}
                    target='_blank'
                  >
                    <FaXTwitter className='h-4 w-4 text-inherit' />
                  </a>
                  <a
                    className='text-gray inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800'
                    href={FOOTER_SOCIAL_LINKS.pinterest}
                    target='_blank'
                  >
                    <FaPinterest className='h-4 w-4 text-inherit' />
                  </a>
                </div>
              </div>
            </div>
            <div className='pb-12  pt-6'>
              <div className='flex flex-col-reverse sm:flex-row sm:items-center sm:justify-between'>
                <div className='text-sm text-gray-600 dark:text-gray-400'>
                  {FOOTER_TEXT.copyright}
                </div>

                <div className='mb-5 flex flex-col gap-4 text-sm sm:gap-8 lg:mb-0 lg:flex-row'>
                  <Link
                    className='text-sm text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 sm:inline-flex'
                    href={{ pathname: '/privacy-policy' }}
                  >
                    {FOOTER_TEXT.privacyPolicy}
                  </Link>
                  <Link
                    className='text-sm text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 sm:inline-flex'
                    href={{ pathname: '/terms-and-conditions' }}
                  >
                    {FOOTER_TEXT.termsAndConditions}
                  </Link>
                  <Link
                    className='text-sm text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 sm:inline-flex'
                    href={{ pathname: '/warranty-disclaimer' }}
                  >
                    Warraty Disclaimer
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {slug !== undefined && (
        <section className='fixed bottom-0 left-0 z-[999] w-full bg-white sm:fixed md:fixed lg:relative'>
          <div className='mx-auto flex h-16 w-full flex-row items-center justify-around border-t border-t-[#DCE0E3] px-3 md:h-16 md:flex-row md:border-t-0 md:px-4'>
            <div className='flex flex-col items-center justify-start gap-y-5'>
              <RebaseEcoModal product={slug || ''} id='foter01' />
            </div>
            <div className='flex min-w-max flex-col items-center justify-start gap-y-5'>
              <Link href={`/product/detail/${slug}`}>
                <Button
                  size='small'
                  title={BUTTON_TEXT.shopNow.btnText}
                  color={
                    slug &&
                    REBATES_FOOTER[slug as keyof typeof REBATES_FOOTER]
                      ?.colorButton
                  }
                  className={
                    slug &&
                    REBATES_FOOTER[slug as keyof typeof REBATES_FOOTER]
                      ?.classNameButton
                  }
                >
                  {BUTTON_TEXT.shopNow.btnText}
                </Button>
              </Link>
            </div>
          </div>
        </section>
      )}
    </footer>
  );
};

export default Footer;
